<template>
  <form v-if="!loadingTemplates" @submit.prevent="uploadProducts" class="mt-3">
    <div class="text-left">
      <label
        for=""
        class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80"
        ><b>Descargue la plantilla</b></label
      >
      <BaseDownload value="Plantilla" :url_download="templateUrl" />
    </div>
    <DragDropExcel
      label="Archivo:"
      buttonLabel="Seleccione los archivos"
      id="complaintFiles"
      accept=".xls,.xlsx"
      accept-text=".xls, .xlsx"
      :single="true"
      :fileList="form.files"
      @files-handler="setMassiveFile"
    />
    <div v-if="feedback" class="p-4 mt-8 rounded-lg bg-red-500 bg-opacity-10">
      <p class="mb-1 text-left text-xs text-red-500 font-semibold">
        Verifique los siguientes errores:
      </p>
      <ul
        v-for="(item, index) in feedback"
        :key="index"
        class="pl-4 text-red-500 list-disc"
      >
        <li>
          <p class="mb-1 text-left text-xs">
            {{ item }}
          </p>
        </li>
      </ul>
    </div>
    <div
      class="w-full mt-8 mr-auto flex flex-col lg:flex-row-reverse lg:justify-end gap-4"
    >
      <Button
        type="submit"
        styles="secondary"
        :block="true"
        :loading="loading"
        :disable="loading"
      >
        Crear
      </Button>

      <Button
        type="button"
        :danger="true"
        :invert="true"
        :block="true"
        :disable="loading"
        @click.native="$router.go(-1)"
      >
        Volver
      </Button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  props: {
    tariffFormat: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        files: [],
      },
      templates: {},
      loading: false,
      loadingTemplates: false,
      feedback: '',
    }
  },
  created() {
    this.fetchTemplates()
  },
  computed: {
    ...mapGetters('tariffs', ['isDeposit', 'isCreditCard', 'isCredit']),
    ...mapState({
      formOptions: 'form',
    }),
    templateUrl() {
      if (this.isCredit(+this.tariffFormat)) {
        return this.templates.credito
      } else if (this.isCreditCard(+this.tariffFormat)) {
        return this.templates.tarjeta_credito
      } else if (this.isDeposit(+this.tariffFormat)) {
        return this.templates.deposito
      }
      return ''
    },
  },
  methods: {
    ...mapActions('tariffs', ['fetchTariffs']),
    async fetchTemplates() {
      this.loadingTemplates = true
      const response = await this.$api.tariffs.getTariffsMassiveTemplates()
      this.loadingTemplates = false
      if (response.status !== 200) return
      this.templates = response.data
    },
    setMassiveFile(files) {
      this.form.files = files
    },
    async uploadProducts() {
      this.feedback = null
      this.loading = true
      const file = await this.$uploadFileFirebase(
        '/massive/',
        this.form.files[0]
      )

      if (!file) {
        this.feedback = 'No se pudo cargar el archivo'
        return
      }
      const format = this.formOptions.optionsTariffFormats.find(
        (op) => op.key === this.tariffFormat
      )

      this.loading = true

      const response = await this.$api.tariffs.updateTariffsMassiveTemplates(
        format.name,
        {
          url_file: file.name,
        }
      )
      this.loading = false
      if (response.status !== 200) {
        this.feedback = response.data
        return
      }
      this.$showNotification('success', 'Se cargo correctamente el archivo')
      this.fetchTariffs()
      this.$router.go(-1)
    },
  },
}
</script>

<style></style>
